import { Gondor } from "../Gondor";

const ACTIONS = {
  PHONE_BRAND: "/phoneBrand/",
  PHONE_BRAND_ITEM: "/phoneBrand/{id}/",
};

export function find(params?: object) {
  return Gondor.API.get(ACTIONS.PHONE_BRAND, { params });
}

export function findOne(id: number | string) {
  id = id.toString();
  return Gondor.API.get(ACTIONS.PHONE_BRAND_ITEM.replace("{id}", id));
}

export function create(body: { [name: string]: string }) {
  return Gondor.API.post(ACTIONS.PHONE_BRAND, body);
}

export function update(id: number | string, body: { [name: string]: string }) {
  id = id.toString();
  return Gondor.API.patch(ACTIONS.PHONE_BRAND_ITEM.replace("{id}", id), body);
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(ACTIONS.PHONE_BRAND_ITEM.replace("{id}", id));
}
